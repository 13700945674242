'use strict';

var windowManager = require('./window-manager');
var querystring = require('querystring');

var getParam = function(name) {
  var params;
  var search = windowManager.get().location.search;

  if(search[0] === '?') {
    search = search.substring(1);
  }

  params = querystring.parse(search);

  return params[name];
};

module.exports = getParam;
