"use strict";

var $ = require("jquery");

var prices = require("./prices");
const fetchProducts = require("./fetch-products");
const productsDataStorage = require("./helpers/products-data-storage");

const DEFAULT_CURRENCY = "USD";
const DEFAULT_CURRENCY_SYMBOL = "$";

var priceSelector = function () {
  fetchProducts().then((data) => {
    if (data.scs) {
      delete data.scs;
    }
    productsDataStorage.set(data);

    let initialCurrency;
    let initialCurrencySymbol;

    try {
      initialCurrency =
        localStorage.getItem("current_currency") || DEFAULT_CURRENCY;
      initialCurrencySymbol =
        localStorage.getItem("current_currency_symbol") ||
        DEFAULT_CURRENCY_SYMBOL;
    } catch (_) {
      initialCurrency = DEFAULT_CURRENCY;
      initialCurrencySymbol = DEFAULT_CURRENCY_SYMBOL;
    }

    const $currencyDropDown = $("#selected-currency");
    const $initialAnchor = $(`a.currency[data-currency=${initialCurrency}]`);

    const setSelectedItem = ($anchor) => {
      const $listItem = $anchor.closest(".currencies-selector li");

      $(".currencies-selector li").each((index, el) => {
        $(el).removeClass("selected");
      });

      $listItem.addClass("selected");
    };

    const swapPrices = (currency) => {
      prices.setPrices(currency);
    };

    const setNavCurrency = (currency, symbol) => {
      $currencyDropDown.html(`${symbol} ${currency}`);
    };

    const setCurrency = (event) => {
      const $anchor = $(event.target).closest("a.currency");
      const selectedCurrency = $anchor.data("currency");
      const currencySymbol = $anchor.data("symbol");

      setSelectedItem($anchor);
      swapPrices(selectedCurrency);
      setNavCurrency(selectedCurrency, currencySymbol);

      try {
        localStorage.setItem("current_currency", selectedCurrency);
        localStorage.setItem("current_currency_symbol", currencySymbol);
      } catch (error) {
        console.error(error);
      }
    };

    $(".currencies-selector li a").each((index, el) => {
      $(el).on("click", setCurrency);
    });

    setNavCurrency(initialCurrency, initialCurrencySymbol);
    setSelectedItem($initialAnchor);
    prices.setPrices(initialCurrency);
  });
};

module.exports = priceSelector;
