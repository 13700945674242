'use strict';

var $ = require('jquery');
var buildMessageMarkup = require('./build-message-markup');

var displayLoading = function(form) {
  var $form = $(form);

  $form.message({
    classes: 'ws-ui-notification-message ws-ui-notification-message--accent',
    message: buildMessageMarkup($form.data('in-progress'))
  });
};

module.exports = displayLoading;