'use strict';

var $ = require('jquery');

var toDataAttribute = function(error) {
  return error.toLowerCase().split(' ').join('-');
};

var getErrorFor = function(form) {
  return function(errorThrown) {
    var dataError = toDataAttribute(errorThrown.message);
    $(form).message('close');

    $(form).message({
      classes: 'alert alert-error alert-danger err-message',
      message_attribute: dataError,
      fallback_message_attribute: 'unknown-error',
      clean: false
    });

    $(form).message({
      classes: 'alert alert-error alert-danger make-correction',
      message_attribute: 'make-correction',
      clean: false
    });
  };
};

module.exports = getErrorFor;
