import {
  PAYWALL_PLAN_CLASS,
  PAYWALL_TOGGLE_CLASS,
  PAYWALL_FEATURES_LIST_CLASS,
  PAYWALL_FEATURES_LIST_DISABLED_CLASS,
  TOGGLE_ATTRIBUTE,
  TOGGLE_SHOW,
  TOGGLE_HIDE,
  SWIPER_WRAPPER_CLASS,
  SWIPER_SLIDE_CLASS,
} from './constants';

const updateSwiperHeight = (element) => {
  const slide = element.closest(`.${SWIPER_SLIDE_CLASS}`);
  const wrapper = slide.closest(`.${SWIPER_WRAPPER_CLASS}`);

  if (!element || !wrapper) return;

  const { height } = slide.getBoundingClientRect();
  
  wrapper.style.height = `${Math.round(height)}px`;
};

const togglePlan = ({ target }) => {
  const plan = target.closest(`.${PAYWALL_PLAN_CLASS}`);
  const toggler = target.closest(`.${PAYWALL_TOGGLE_CLASS}`);
  const featureList = plan.querySelector(`.${PAYWALL_FEATURES_LIST_CLASS}`);
  
  if (!featureList || !toggler) return;

  featureList.classList.toggle(PAYWALL_FEATURES_LIST_DISABLED_CLASS);

  const dataToggleValue = toggler.getAttribute(TOGGLE_ATTRIBUTE);

  if (dataToggleValue === TOGGLE_SHOW) {
    toggler.setAttribute(TOGGLE_ATTRIBUTE, TOGGLE_HIDE);
  } else {
    toggler.setAttribute(TOGGLE_ATTRIBUTE, TOGGLE_SHOW); 
  }

  updateSwiperHeight(toggler);
};

export default () => {
  const plans = Array.from(document.querySelectorAll(`.${PAYWALL_TOGGLE_CLASS}`));

  plans.forEach(plan => {
    if (!plan) return;
    plan.addEventListener('click', togglePlan);
  });
};
