'use strict';

var $ = require('jquery');
var buildMessageMarkup = require('./build-message-markup');

var getSuccessFor = function(form) {
  return function() {
    var $form = $(form);

    $form.message({
      classes: 'ws-ui-notification-message ws-ui-notification-message--success',
      message: buildMessageMarkup($form.data('success'))
    });
  };
};

module.exports = getSuccessFor;