'use strict';

var xhrHelpers = require('xhrhelpers');
var yousers = require('@yola/yousersjs');

var userData = null;
var fetchPromise = null;

module.exports = function (options) {
  var opts = options || {};
  var useCachedData = opts.useCachedData || false;

  if (fetchPromise) {
    return fetchPromise;
  }

  if (useCachedData && userData) {
    return Promise.resolve(userData);
  }

  fetchPromise = yousers.user()
    .get()
    .then(xhrHelpers.responseObject)
    .then(function (data) {
      userData = data;
      fetchPromise = null;

      return data;
    })
    .catch(function (error) {
      fetchPromise = null;

      throw error;
    });

  return fetchPromise;
};
