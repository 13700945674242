import {
  PAYWALL_CONTAINER_DISABLED_CLASS,
  PAYWALL_CONTAINER_CLONE_CLASS,
  PAYWALL_CONTAINER_CLASS,
  PAYWALL_CONTAINER_HOSTING_CLASS,
  PAYWALL_CONTAINER_ECOMMERCE_CLASS,
  PAYWALL_DESKTOP_CLASS,
  PAYWALL_CLASS,
  PAYWALL_HOSTING_CLASS,
  PAYWALL_ECOMMERCE_CLASS,
  PAYWALL_PLANS_CLASS,
  PAYWALL_BODY_CLASS,
  MAX_SCROLL_WINDOW_WIDTH,
  paywallNamesMap,
  activeClonesMap,
} from './constants';
import applyBillingToggle from './billing-toggle';
import applyMobileToggle from './mobile-toggle';
import makeCloneActive from './make-clone-active';
import isActiveTab from './is-active-tab';

const isElementInViewport = (body, plansClone) => {
  let element = body;
  const width = element.offsetWidth;
  const height = element.offsetHeight;
  const plansCloneHeight = plansClone ? plansClone.getBoundingClientRect().height : 0;
  let top = element.offsetTop;
  let left = element.offsetLeft;

  while(element.offsetParent) {
    element = element.offsetParent;
    top += element.offsetTop;
    left += element.offsetLeft;
  }

  return (
    top - plansCloneHeight < (window.pageYOffset + window.innerHeight) &&
    left < (window.pageXOffset + window.innerWidth) &&
    (top - plansCloneHeight + height) > window.pageYOffset &&
    (left + width) > window.pageXOffset
  );
};

const createClone = (plans) => {
  const container = document.createElement('div');
  const paywall = document.createElement('div');
  const clone = plans.cloneNode(true);
  const isHosting = !!plans.closest(`.${PAYWALL_HOSTING_CLASS}`);
  const isEcommerce = !!plans.closest(`.${PAYWALL_ECOMMERCE_CLASS}`);

  container.classList.add(PAYWALL_CONTAINER_CLASS, PAYWALL_CONTAINER_CLONE_CLASS, PAYWALL_CONTAINER_DISABLED_CLASS);

  if (isHosting) {
    container.classList.add(PAYWALL_CONTAINER_HOSTING_CLASS);
    paywall.classList.add(PAYWALL_HOSTING_CLASS);
  }

  if (isEcommerce) {
    container.classList.add(PAYWALL_CONTAINER_ECOMMERCE_CLASS);
    paywall.classList.add(PAYWALL_ECOMMERCE_CLASS);
  }

  paywall.classList.add(PAYWALL_CLASS);

  paywall.appendChild(clone);
  container.appendChild(paywall);

  return container;
};

const getCloneFromElement = (element) => {
  if (!element || element.closest(`.${PAYWALL_CLASS}`)) return null;
  
  const paywallName = Array.from(element.closest(`.${PAYWALL_CLASS}`).classList)
    .find(className => className === PAYWALL_HOSTING_CLASS || className === PAYWALL_ECOMMERCE_CLASS);
  const containerName = paywallNamesMap[paywallName];
  const clones = Array.from(document.querySelectorAll(`.${PAYWALL_CONTAINER_CLONE_CLASS}`));
  
  return clones.find(clone => clone.classList.contains(containerName));
};

const injectClone = (plans) => {
  const clone = createClone(plans);
  document.body.appendChild(clone); 
};

const removeClones = () => {
  const clones = document.querySelectorAll(`.${PAYWALL_CONTAINER_CLONE_CLASS}`);
  
  if (!clones.length) return;
  
  clones.forEach(clone => clone.remove());
};

const showPlans = (element) => {
  if (!element) return;
  element.classList.remove(PAYWALL_CONTAINER_DISABLED_CLASS);
};

const hidePlans = (element) => {
  if (!element) return;
  element.classList.add(PAYWALL_CONTAINER_DISABLED_CLASS);
};

const onScrollHandler = (body) => {
  const clone = getCloneFromElement(body);
  const plansClone = clone && clone.querySelector(`.${PAYWALL_PLANS_CLASS}`);

  if (body && isElementInViewport(body, plansClone)) return;

  hidePlans(clone);
};

const getActiveCloneKey = (element) => {
  const paywalls = Object.keys(paywallNamesMap);
  const paywallsClones = Object.keys(activeClonesMap);
  const activeCloneName = paywallNamesMap[paywalls.find(paywall => element.closest(`.${paywall}`))];
  const activeCloneKey = paywallsClones.find(key => activeClonesMap[key] === activeCloneName);

  if (!isActiveTab(element)) return -1;

  return activeCloneKey;
};

const onWindowSize = (plans, observer) => {
  if (window.innerWidth < MAX_SCROLL_WINDOW_WIDTH) {
    observer.unobserve(plans);
    removeClones();
  } else {
    const hostingClone = document.querySelector(`.${PAYWALL_CONTAINER_HOSTING_CLASS}`);
    const ecommerceClone = document.querySelector(`.${PAYWALL_CONTAINER_ECOMMERCE_CLASS}`);
    const activeCloneKey = getActiveCloneKey(plans);
    
    if (!hostingClone || !ecommerceClone) {
      injectClone(plans);
      observer.observe(plans);
      makeCloneActive(activeCloneKey);
      applyBillingToggle();
    }
  }
};

const handleObserver = ([entry]) => {
  const { target, intersectionRatio, intersectionRect } = entry;
  const isIntercepted = intersectionRatio < 1 && intersectionRect.top < 0;
  const clone = getCloneFromElement(target);

  if (isIntercepted && isActiveTab(target)) {
    showPlans(clone);
  } else {
    hidePlans(clone);
  }
};

const main = () => {
  const plans = document.querySelectorAll(`.${PAYWALL_DESKTOP_CLASS} .${PAYWALL_PLANS_CLASS}`);
  const hostingBody = document.querySelector(`.${PAYWALL_HOSTING_CLASS} .${PAYWALL_BODY_CLASS}`);

  plans.forEach(plan => {
    if (!plan) return;

    const observer = new IntersectionObserver(handleObserver, {
      threshold: [1],
      rootMargin: '33px 0px 0px 0px',
    });
    
    if (window.innerWidth >= MAX_SCROLL_WINDOW_WIDTH) {
      observer.observe(plan);
      injectClone(plan);
    }

    window.addEventListener('resize', () => onWindowSize(plan, observer));
  });
  
  document.addEventListener('scroll', () => onScrollHandler(hostingBody));
  document.addEventListener('paywallChange', () => {
    const clone = getCloneFromElement(hostingBody);
    hidePlans(clone);
  });
};

document.addEventListener('DOMContentLoaded', () => {
  main();
  applyBillingToggle();
  applyMobileToggle();
});
