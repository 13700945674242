import {
  ACTIVE_TAB_CLASS,
  PAYWALL_PLAN_TOGGLER_CLASS,
  PAYWALL_TOGGLER_PROMO_CLASS,
  PAYWALL_TOGGLER_PROMO_DISABLED_CLASS,
  PAYWALL_ITEM_ONLY_ANNUALLY_CLASS,
  PAYWALL_FEATURE_LIST_ONLY_ANNUALLY_CLASS,
  PAYWALL_FEATURE_LIST_UNAVAILABLE_CLASS,
  PAYWALL_LIST_CELL_CLASS,
  PAYWALL_LIST_CELL_UNAVAILABLE_CLASS,
  PAYWALL_LIST_CELL_AVAILABLE_CLASS,
  PAYWALL_PLAN_PRICE_DESCRIPTION_CLASS,
  PAYWALL_PLAN_PRICE_DESCRIPTION_HIDDEN_CLASS,
  MOBILE_TOGGLER_CLASS,
  TOGGLE_TRIGGER_CLASS,
  TOGGLE_TRIGGER_MOBILE_CLASS,
  TOGGLE_TARGET_ATTRIBUTE,
  TOGGLE_TERM_ATTRIBUTE,
  BILLING_TOGGLE_TARGET_ATTRIBUTE,
  MONTH_VALUE,
  YEAR_VALUE,
  ACTIVE_CLASS,
  INVARIABLE_ATTRIBUTE,
} from './constants';
import isActiveTab from './is-active-tab';

const getLink = (element) => {
  const link = element.querySelector('.paywall__plan-button');
  const href = link.getAttribute('href');

  return {
    link,
    href,
  };
};

const isActiveToggler = (element) => !!element.classList.contains(ACTIVE_CLASS);

const makeActiveToggler = (term, options = {}) => {
  const { paywall, isActiveTabIgnored } = options;
  let planTogglers = document.querySelectorAll(`.${PAYWALL_PLAN_TOGGLER_CLASS}`);

  if (paywall) {
    planTogglers = paywall.querySelectorAll(`.${PAYWALL_PLAN_TOGGLER_CLASS}`);
  }

  planTogglers.forEach(planToggler => {
    if (!isActiveTab(planToggler, isActiveTabIgnored)) return;

    if (term === YEAR_VALUE) {
      planToggler.classList.remove(ACTIVE_CLASS);
    } else {
      if (isActiveToggler(planToggler)) return;
      planToggler.classList.add(ACTIVE_CLASS);
    }
  });
};

const makeActiveMobileToggler = (target, term, options = {}) => {
  const { isActiveTabIgnored } = options;

  if (!isActiveTab(target, isActiveTabIgnored)) return;

  const activeTab = target.closest(`.${ACTIVE_TAB_CLASS}`);
  let mobileToggler = activeTab && activeTab.querySelector(`.${MOBILE_TOGGLER_CLASS}`);

  if (isActiveTabIgnored) {
    mobileToggler = target.querySelector(`.${MOBILE_TOGGLER_CLASS}`);
  }

  if (!mobileToggler) return;

  if (term === YEAR_VALUE) {
    if (isActiveToggler(mobileToggler)) return;

    mobileToggler.classList.add(ACTIVE_CLASS);
  } else {
    mobileToggler.classList.remove(ACTIVE_CLASS);
  }

  makeActiveToggler(term);
};

const makeActiveTerm = (activeElements, inactiveElements, options = {}) => {
  const { disabled, isActiveTabIgnored } = options;
  const BUTTON_ACTIVE_CLASS = 'paywall-button--primary';
  const BUTTON_INACTIVE_CLASS = 'paywall-button--default';
  const BUTTON_DISABLED_CLASS = 'paywall-button--disabled';

  inactiveElements.forEach(inactiveElement => {
    if (!isActiveTab(inactiveElement, isActiveTabIgnored)) return;

    const isMobileTrigger = inactiveElement.classList.contains(TOGGLE_TRIGGER_MOBILE_CLASS);

    if (isMobileTrigger) return;

    inactiveElement.classList.remove(ACTIVE_CLASS, BUTTON_ACTIVE_CLASS);
    inactiveElement.classList.add(BUTTON_INACTIVE_CLASS);

    if (!disabled) return;

    inactiveElement.classList.add(BUTTON_DISABLED_CLASS);
  });
  activeElements.forEach(activeElement => {
    if (!isActiveTab(activeElement, isActiveTabIgnored)) return;

    const isMobileTrigger = activeElement.classList.contains(TOGGLE_TRIGGER_MOBILE_CLASS);

    if (isMobileTrigger) return;

    activeElement.classList.add(ACTIVE_CLASS, BUTTON_ACTIVE_CLASS);
    activeElement.classList.remove(BUTTON_INACTIVE_CLASS);

    if (!disabled) return;

    activeElement.classList.remove(BUTTON_DISABLED_CLASS);
  });
};

const toggleBanner = (currentTerm, options = {}) => {
  const { paywall, isActiveTabIgnored } = options;
  let promoBanners = document.querySelectorAll(`.${PAYWALL_TOGGLER_PROMO_CLASS}`);

  if (paywall) {
    promoBanners = paywall.querySelectorAll(`.${PAYWALL_TOGGLER_PROMO_CLASS}`);
  }

  promoBanners.forEach(promoBanner => {
    if (!isActiveTab(promoBanner, isActiveTabIgnored)) return;

    if (currentTerm === MONTH_VALUE) {
      promoBanner.classList.add(PAYWALL_TOGGLER_PROMO_DISABLED_CLASS);
    } else {
      promoBanner.classList.remove(PAYWALL_TOGGLER_PROMO_DISABLED_CLASS);
    }
  });
};

const togglePriceDescription = (currentTerm) => {
  const priceDescriptions = document.querySelectorAll(`.${PAYWALL_PLAN_PRICE_DESCRIPTION_CLASS}`);

  priceDescriptions.forEach(priceDescription => {
    if (!isActiveTab(priceDescription)) return;

    if (currentTerm === MONTH_VALUE) {
      priceDescription.classList.add(PAYWALL_PLAN_PRICE_DESCRIPTION_HIDDEN_CLASS);
    } else {
      priceDescription.classList.remove(PAYWALL_PLAN_PRICE_DESCRIPTION_HIDDEN_CLASS);
    }
  });
}

const disablePaywallProposition = (currentTerm) => {
  const annualItem = document.querySelector(`.${PAYWALL_ITEM_ONLY_ANNUALLY_CLASS}`);
  const targets = Array.from(annualItem.querySelectorAll(`.${PAYWALL_LIST_CELL_CLASS}:not([${INVARIABLE_ATTRIBUTE}])`));

  if (!isActiveTab(annualItem)) return;

  targets.forEach((target) => {
    if (!isActiveTab(target)) return;

    if (currentTerm === MONTH_VALUE) {
      target.classList.remove(PAYWALL_LIST_CELL_AVAILABLE_CLASS);
      target.classList.add(PAYWALL_LIST_CELL_UNAVAILABLE_CLASS);
    } else {
      target.classList.remove(PAYWALL_LIST_CELL_UNAVAILABLE_CLASS);
      target.classList.add(PAYWALL_LIST_CELL_AVAILABLE_CLASS);
    }
  });
};

const disableMobilePaywallProposition = (currentTerm) => {
  const annualItems = document.querySelectorAll(`.${PAYWALL_FEATURE_LIST_ONLY_ANNUALLY_CLASS}`);

  annualItems.forEach(annualItem => {
    if (annualItem && !isActiveTab(annualItem)) return;

    if (currentTerm === MONTH_VALUE) {
      annualItem.classList.add(PAYWALL_FEATURE_LIST_UNAVAILABLE_CLASS);
    } else {
      annualItem.classList.remove(PAYWALL_FEATURE_LIST_UNAVAILABLE_CLASS);
    }
  });
};

const getToggleTerms = () => Array.from(document.querySelectorAll('.paywall-billing-toggle__trigger[data-billing-toggle-term]'));

export default () => {
  const toggleTerms = getToggleTerms();
  const toggles = document.querySelectorAll('.paywall-billing-toggle[data-billing-toggle-target]');

  toggles.forEach((toggle) => {
    const toggleTargets = document.querySelectorAll(toggle.getAttribute(BILLING_TOGGLE_TARGET_ATTRIBUTE));
    const activeToggleTerm = toggleTerms.find(isActiveToggler);
    const activeToggleTermAttr = activeToggleTerm && activeToggleTerm.getAttribute(TOGGLE_TERM_ATTRIBUTE);

    toggleTargets.forEach((target) => {
      const { link, href } = getLink(target);
      const url = new URL(href);
      const searchParams = new URLSearchParams(url.search);
      const currentTerm = activeToggleTermAttr === 'month' ? 'P1M' : 'P1Y';

      if (!searchParams.has('term')) {
        searchParams.set('term', currentTerm);
        url.search = searchParams.toString();
        link.setAttribute('href', url.href);
      }
    });

    const setState = (e) => {
      const { target } = e;

      // eslint-disable-next-line max-len
      const targetNewValue = target.getAttribute(TOGGLE_TERM_ATTRIBUTE);
      // eslint-disable-next-line max-len
      const isToggleTriggered = target.classList.contains(TOGGLE_TRIGGER_CLASS);

      if (isToggleTriggered) {
        if (!isActiveToggler(target)) {
          const syncToggleTerms = getToggleTerms();
          const activeNonTriggeredTerms = syncToggleTerms.filter(toggleTerm => toggleTerm.getAttribute(TOGGLE_TERM_ATTRIBUTE) === targetNewValue);

          makeActiveTerm([target, ...activeNonTriggeredTerms], syncToggleTerms);
          toggleBanner(targetNewValue);
          togglePriceDescription(targetNewValue);
          disablePaywallProposition(targetNewValue);
          disableMobilePaywallProposition(targetNewValue);
          makeActiveToggler(targetNewValue);
          makeActiveMobileToggler(target, targetNewValue);
        }

        toggleTargets.forEach((toggleTarget) => {
          const { link, href } = getLink(toggleTarget);

          if (!isActiveTab(link)) return;

          toggleTarget.setAttribute(TOGGLE_TARGET_ATTRIBUTE, targetNewValue);

          if (targetNewValue === MONTH_VALUE) {
            link.setAttribute('href', href.replaceAll(/term=P1Y/gi, 'term=P1M'));
          } else {
            link.setAttribute('href', href.replaceAll(/term=P1M/gi, 'term=P1Y'));
          }
        });
      }
    }

    toggleTerms.forEach(toggleTerm => toggleTerm.addEventListener('click', setState));
  });
};
