'use strict';

var $ = require('jquery');

var displayLoading = function(form) {
  return $(form).message({
    classes: 'alert alert-info loading',
    message_attribute: 'in-progress'
  });
};

module.exports = displayLoading;
