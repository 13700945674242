let products = {};

const productsDataStorage = {
  set(data) {
    products = data;
  },
  get() {
    return products;
  },
};

module.exports = productsDataStorage;
