"use strict";

var Handlebars = require("handlebars/runtime");

var transHelper = function(text) {
  return gettext(text);
};

Handlebars.registerHelper("trans", transHelper);

module.exports = transHelper;
