/* global Raven */
'use strict';

var recaptcha = require('./recaptcha');

var renderRecaptcha = function(recaptchaSelector) {
  var container = document.querySelector(recaptchaSelector);

  recaptcha.render(container)
    .catch(Raven.captureException);
};

module.exports = renderRecaptcha;
