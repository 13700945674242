var $ = require('jquery');
var cookies = require('js-cookie');
var getPublicDomain = require('./helpers/get-public-domain');

var languageSelector = function() {
  var ENGLISH_LANG = 'en';
  var currentLanguageLink = $('.languages-selector .selected');
  currentLanguageLink.click(function(e) {
    e.preventDefault();
  });

  var formateGoogleTransLang = function (lang) {
    return '/' + ENGLISH_LANG + '/' + lang;
  };

  var formateGoogleTransString = function (lang) {
    return lang === 'pt-br' ? 'pt' : lang;
  };

  var manageCookies = function () {
    var langCookie = cookies.get('current_lang');
    var currentLang = formateGoogleTransLang(langCookie);
    var cookieDomain = '.' + getPublicDomain().split('.').splice(-2).join('.');

    var GOOGLETRANS_COOKIE = 'googtrans';

    if (cookies.get(GOOGLETRANS_COOKIE) === formateGoogleTransString(currentLang)) {
      var hreflang = $(this).attr('hreflang');
      var chosenLang = formateGoogleTransLang(hreflang);
      var transLangString = formateGoogleTransString(chosenLang);

      cookies.set('googtrans', transLangString);
      cookies.set('googtrans', transLangString, { domain: cookieDomain });
    }

    if ($(this).hasClass('en')) {
      cookies.set('current_lang', ENGLISH_LANG, { domain: '.' + getPublicDomain() });
      cookies.remove('googtrans', { domain: cookieDomain });
      cookies.remove('googtrans');
    }
  };

  var languageLinks = $('.languages-selector a, .language-selector a');
  languageLinks.click(manageCookies);
};

module.exports = languageSelector;
