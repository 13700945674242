'use strict';

var requests = require('@yola/yosupportjs').requests;
var displayLoading = require('../helpers/form-display-loading');
var submitButton = require('../helpers/submit-button');
var getRedirectFor = require('../helpers/redirect');
var renderRecaptcha = require('../helpers/render-captcha');
var abuseError = require('./abuse-error');
var dmca = require('./dmca');
var fieldsHelper = require('./fields-helper');


var $spinner;
var form;

var hideSpinner = function () {
  $spinner && $spinner.message('close');
};

var handleError = function (error) {
  error.response.json()
    .then(function (res) {
      Object.keys(res).forEach(abuseError.showError, res);
    });

  submitButton.enable(form);
  hideSpinner();
};

var getFormData = function (subject) {
  var recaptcha = fieldsHelper.getValue('g-recaptcha-response');
  var formData = fieldsHelper.extractData(subject);

  formData.recaptcha_response = recaptcha;

  return formData;
};

var onSubmit = function (event) {
  event.preventDefault();

  submitButton.disable(form);
  $spinner = displayLoading(form);

  abuseError.clearAllErrors();

  var subject = fieldsHelper.getValue('subject');

  requests.abuse(subject, getFormData(subject))
    .then(getRedirectFor(form))
    .catch(handleError);
};


var initSubjectSwitcher = function () {
  var onSubjectChange = function () {
    var subject = this.value;

    document.querySelector('.sections').dataset.subject = subject;
    window.location.hash = subject;
  };

  document.getElementById('subject')
    .addEventListener('change', onSubjectChange, false);
};

var onFieldChange = function (event) {
  var field = event.target;

  if (field.classList.contains('invalid-field')) {
    abuseError.clearError(field);
  }
};

var init = function (form) {
  renderRecaptcha('#report-abuse-recaptcha');
  dmca.renderDmca('.dmca-start', '.dmca-end');
  initSubjectSwitcher();

  form.addEventListener('submit', onSubmit, false);
  form.addEventListener('change', onFieldChange, false);
};

form = document.getElementById('abuse');
form && init(form);
