import Swiper, { HashNavigation } from 'swiper';
import isTouchDevice from './helpers/is-touch-device';
import makeCloneActive from './paywall/make-clone-active';

const TAB_LINK_SELECTOR = '.tab-link';
const TAB_LINKS_SELECTOR = '.tab-links';
const TAB_CONTENT_SELECTOR = '.tab-content';
const HOVER_CLASS = 'tab-link--hoverable';
const TAB_CONTROL_SELECTOR = '.tab-control'
const HASH_ATTRIBUTE = 'data-hash';

Swiper.use([HashNavigation]);

function useTabs() {
  const isTouch = isTouchDevice();
  const tabLinks = document.querySelectorAll(TAB_LINK_SELECTOR);
  const tabControls = document.querySelectorAll(TAB_CONTROL_SELECTOR);

  const tabLinksSwiper = new Swiper(TAB_LINKS_SELECTOR, {
    slidesPerView: 'auto',
    normalizeSlideIndex: false,
    simulateTouch: false,
    centeredSlides: true,
    centeredSlidesBounds: true,
    watchOverflow: true,
    touchReleaseOnEdges: true,
    hashNavigation: {
      replaceState: true,
      watchState: true,
    },
    breakpoints: {
      675: {
        centeredSlides: false,
        centeredSlidesBounds: false,
      },
    },
  });

  const contentSwiper = new Swiper(TAB_CONTENT_SELECTOR, {
    allowTouchMove: false,
    autoHeight: true,
    speed: isTouch ? 300 : 1, // very fast animation to disable swipe effect for desktop
    initialSlide: tabLinksSwiper.activeIndex,
    observer: true,
    observeParents: true
  });

  contentSwiper.on('observerUpdate', () => {
    contentSwiper.updateAutoHeight(0);
  });

  contentSwiper.on('slideChange', () => {
    document.dispatchEvent(new Event('paywallChange'));
  });

  contentSwiper.on('resize',  () => {
    if (tabLinksSwiper.activeIndex !== contentSwiper.activeIndex) {
      contentSwiper.slideTo(tabLinksSwiper.activeIndex, 0, false);
    }
  });

  tabLinksSwiper.params.control = contentSwiper;

  // swiper `slideToClickedSlide` prop doesn't always work correctly
  tabLinks.forEach((link, index) => {
    if (isTouch) {
      link.classList.add(HOVER_CLASS);
    }

    link.addEventListener('click', (event) => {
      event.preventDefault();

      tabLinksSwiper.slideTo(index);
      contentSwiper.slideTo(index);
      makeCloneActive(index);
    });
  });

  tabControls.forEach((tabControl) => {
    tabControl.addEventListener('click', (event) => {
      event.preventDefault();
      const hash = tabControl.getAttribute(HASH_ATTRIBUTE);
      const correspondingTab = Array.from(tabLinks).find((tabLink) => tabLink.getAttribute(HASH_ATTRIBUTE) === hash);
      if (correspondingTab) {
        correspondingTab.click();
        correspondingTab.scrollIntoView();
      }
    });
  })

  makeCloneActive(tabLinksSwiper.activeIndex);
}

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector(TAB_LINKS_SELECTOR) && document.querySelector(TAB_CONTENT_SELECTOR)) {
    useTabs();
  }
});
