var yosubscriptionsjs = require('@yola/yosubscriptionsjs');

function fetchSubscriptions(options) {
  var opts = options || {};

  return yosubscriptionsjs()
    .subscriptions(opts);
}

module.exports = fetchSubscriptions;
