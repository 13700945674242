const yoproducts = require('@yola/yoproductsjs').default;
const config = require('./config');

let serviceClient;

const yoproductsServiceClient = {
  get() {
    if (!serviceClient) {
      const apiUrl = config.get('publicApi');

      serviceClient = {
        actions: yoproducts.actions,
        requests: yoproducts.initRequests(apiUrl),
      };
    }

    return serviceClient;
  },
};

module.exports = yoproductsServiceClient;
