'use strict';

var dmcaMessage = gettext(
  'Yes, I %(fname)s %(lname)s certify under perjury that I \n' +
  'am authorized to act on behalf of the owner of the exclusive right \n' +
  'that is legally infringed.'
);

var dmca = dmcaMessage.split(' %(fname)s %(lname)s ');

var dmcaStart = dmca[0];
var dmcaEnd = dmca[1];

var renderDmca = function (startSelector, endSelector) {
  document.querySelector(startSelector).textContent = dmcaStart;
  document.querySelector(endSelector).textContent = dmcaEnd;
};

var generateDmcaMessage = function (firstName, lastName) {
  return [
    dmcaStart,
    firstName,
    lastName,
    dmcaEnd
  ].join(' ');
};

exports.renderDmca = renderDmca;
exports.generateDmcaMessage = generateDmcaMessage;
