"use strict";

var Handlebars = require("handlebars/runtime");
var config = require("./config");

var localeHelper = function(href) {
  if (config.get("locale") === ""){
    return href;
  }

  var locale = "/" + config.get("locale");
  if (href[0] !== "/"){
      locale += "/";
  }
  return locale + href;
};

Handlebars.registerHelper("locale", localeHelper);

module.exports = localeHelper;
