/* libs.js is the starting point for yolacom's
 * common/shared libs bundle. This file is not
 * a dumping ground, if something needs added
 * to the libs bundle make a seperate file with
 * the code and require it here.
 */
require('bindpolyfill');
require('number-to-locale-string');
require('../../../collected_static/bower_components/h5bp-console-polyfill/console.js');

var Backbone = require('backbone');
var bluebird = require('bluebird');
var jQuery = require('jquery');
var lodash = require('lodash');

/* Manually attach jQuery, lodash, Backbone
 * to their respective global objects. This is necessary
 * for libraries that assume their dependencies are
 * globally available.
 */

window.$ = jQuery;
window.jQuery = jQuery;
window._ = lodash;
window.Backbone = Backbone;
Backbone.$ = jQuery;
window.Promise = bluebird;

// Remove any browser implementation of fetch that doesn't use global promise
window.fetch = undefined;
require('whatwg-fetch');

/* Include bower dependencies */
require('./bower-dependencies');

/* Include local dependencies: order matters */
require('./use-fancybox');
require('./domain-selector-init');
require('./carousel')();
require('./form-defaults')();
require('./language-selector')();
require('./pricing')();
require('./pricing-selector')();
require('./password-reset');
require('./forgot-password');
require('./contact-support');
require('./report-abuse/report-abuse');
require('./paywall');
require('./tabs');
