'use strict';

var getRedirectFor = function(form) {
  return function () {
    var next = form.dataset.redirect;

    if (next) {
      window.location.assign(decodeURIComponent(next));
    }
  };
};

module.exports = getRedirectFor;
