var $ = require('jquery');
var Handlebars = require('handlebars');
var bannerTemplate = require('./banner-template.hbs');
var discountTemplate = require('./discount-template.hbs');
var timer = require('../../helpers/timer')

function injectPaywallDiscountBanner(element, options) {
  var discountValue = options.discountValue;
  var discountDuration = options.discountDuration;
  var timerInstance = timer.create(discountDuration);
  var time = timerInstance.getTime();

  var template = Handlebars.compile(bannerTemplate({
    linkUrl: location.origin + '/limited-time-offer-terms-and-conditions',
    discountValue: discountValue,
    discountTimer: time.toString(),
  }));

  $(template({
    discount: discountTemplate({
      discountValue,
    })
  })).insertBefore(element);

  var timerNode = $('.paywall-discount-banner__timer');

  timerInstance.on('change', function (timeObject) {
    timerNode.html(timeObject.toString());
  });

  timerInstance.on('finish', function () {
    $('.paywall-discount-banner').remove();
  });

  timerInstance.run();
}

module.exports = injectPaywallDiscountBanner;
