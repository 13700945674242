var $ = require('jquery');
require('./helpers/yohandlebars');
var getDiscountInfo = require('./helpers/get-discount-info');
var injectPaywallDiscountBanner = require('./ui/paywall-discount-banner');


var pricing = function() {

    var isPricingPage = $('#pricing').length > 0;
    var $subNav = $('.sub-nav');
    var $subNavItems = $('.sub-nav li');
    var $links = $('ul.scrolling a');
    var hashs = $links.map(function() {
        return $($(this).attr('href'));
    });
    var activeId;

    var applyStickyNav = function () {
        if ( $(window).scrollTop() >= 138) {
            $subNav.addClass('sub-nav-fixed');
        } else {
            $subNav.removeClass('sub-nav-fixed');
        }
    };

    var spy = function() {
      var currentTop = $(this).scrollTop() + $subNav.height() + 1;
      var sections = hashs.map(function(){
        if (this.offset().top < currentTop ) {
           return this;
        }
      });

      var currentId = $(sections[sections.length-1]).attr('id');

      if (activeId !== currentId) {
        $subNavItems.removeClass('active');

        activeId = currentId;

        var current = $links.filter(function() {
          return $(this).attr('href') === '#' + activeId;
        });

        current.parent().addClass('active');
      }
    };

    var $window = $(window);

    if (isPricingPage) {
        $window.on('scroll', applyStickyNav);
        $window.on('scroll', spy);
    }

    $('.price-features li').tooltip({
        'placement': 'bottom',
        'animation': false
    });
    $('#fifteen-things ol span').tooltip({
        'placement': 'top',
        'animation': false
    });
    if ($window.width() < 600){
        $('.responsive-table table td span').tooltip({
            'placement': 'top',
            'animation': false
        });
    }
    $('table td span').tooltip({
        'placement': 'right',
        'animation': false
    });
    $('.online-store-row ul span').tooltip({
        'placement': 'top',
        'animation': false
    });

    if (isPricingPage) {
        getDiscountInfo()
            .then(function (discountInfo) {
                if (discountInfo.duration > 0 && discountInfo.value) {
                    var element = $('.paywall-hosting').parent().not('.paywall__container--disabled');

                    injectPaywallDiscountBanner(element, {
                        discountValue: discountInfo.value,
                        discountDuration: discountInfo.duration
                    });
                }
            });

    }

};

module.exports = pricing;
