const yoproductsServiceClient = require("./helpers/yoproducts-service-client");

module.exports = () => {
  const userId = document.body.getAttribute("data-user-id");
  const options = null;
  const query = {};

  if (userId) {
    query.extra_prices = "no_discounts";
  } else if (window.INITIAL_PACKAGE_DISCOUNT) {
    query.extra_prices =
      "initial_package_discount:" + window.INITIAL_PACKAGE_DISCOUNT;
  }

  const yoproducts = yoproductsServiceClient.get();

  return Promise.resolve(yoproducts.requests.productsTree(options, query));
};
