'use strict';

var getSubmitButton = function(form) {
  return form.querySelector('button[type=submit]');
};

var disable = function(form) {
  var submitButton = getSubmitButton(form);

  submitButton.classList.add('disabled');
  submitButton.setAttribute('disabled', '');
};

var enable = function(form) {
  var submitButton = getSubmitButton(form);

  submitButton.classList.remove('disabled');
  submitButton.removeAttribute('disabled');
};

exports.disable = disable;
exports.enable = enable;
