var $ = require('jquery');

require("../../../collected_static/bower_components/jcarousel/lib/jquery.jcarousel");

var carousel = function() {

    var $ul = $('.carousel ul');
    if ($ul.length < 1) {
        return;
    }

    var offset = $ul.css('left'),
        static_url = $ul.data('static_url'),
        imgs = ['img/slider/04_kkjc2scientific',
                'img/slider/05_joescoffee',
                'img/slider/06_artbytatiana',
                'img/slider/07_blueprintforjonas',
                'img/slider/08_bodybar',
                'img/slider/09_channysphotography',
                'img/slider/02_punypixel',
                'img/slider/03_poppymoe'];

    $.each(imgs, function(i, img) {
        var src = static_url + img + '_tn.jpg',
            href = static_url + img + '.jpg',
            $image = $('<img>').attr('src', src),
            $link = $('<a>')
                .data('fancybox-group', 'g1')
                .attr('href', href)
                .addClass('fancybox'),
            $li = $('<li>');

        $link.append($image);
        $li.append($link);
        $ul.append($li);
    });

    function build_controls(carousel) {
        $('.carousel_controls .next').click(function(e) {
            e.preventDefault();
            carousel.next();
            carousel.startAuto(0);
        });
        $('.carousel_controls .previous').click(function(e) {
            e.preventDefault();
            carousel.prev();
            carousel.startAuto(0);
        });
        $ul.css({'left':offset});
    }

    $('.carousel').jcarousel({
        'initCallback': build_controls,
        'buttonNextHTML': null,
        'buttonPrevHTML': null,
        'scroll': ($('.carousel').parent().hasClass('arrow_slider')) ? 3 : 1,
        'wrap': 'circular',
        'auto': ($('.carousel').parent().hasClass('arrow_slider')) ? 0 : 5
    });

};

module.exports = carousel;
