/* This imports all bower dependencies for application code
 * which attach themselves to the window or other global objects.
 * This is equivalent to adding <script src="..."></script>
 * tags for each of these files.
 */
require('../../../collected_static/bower_components/gettext/gettext');
require('../../../collected_static/bower_components/inputmask/dist/jquery.inputmask.bundle');
require('../../../collected_static/bower_components/bootstrap2/js/bootstrap-modal');
require('../../../collected_static/bower_components/bootstrap2/js/bootstrap-transition');
require('../../../collected_static/bower_components/bootstrap2/js/bootstrap-tooltip');
require('../../../collected_static/bower_components/domainselector/domainselector/static/domainselector/script');
require('../../../collected_static/bower_components/jquery-messages/dist/messages');
require('../../../collected_static/bower_components/fancybox/source/jquery.fancybox');
