'use strict';

var requests = require('@yola/yousersjs').requests;
var displayLoading = require('./helpers/latitude/form-display-loading');
var getErrorFor = require('./helpers/latitude/form-error');
var getSuccessFor = require('./helpers/latitude/form-success');

var forms = [].slice.call(document.querySelectorAll('.forgot-form'));

var request = function() {
  var options = { email: document.getElementById('id_email').value };

  return requests.passwordResetTokens(options);
};

var switchButtonState = function (form, isLoading) {
  var loadingClassName = 'ws-ui-action-button--loading';

  var submitButton = form.querySelector('button[type=submit]');

  if (isLoading) {
    submitButton.classList.add(loadingClassName);
  } else {
    submitButton.classList.remove(loadingClassName);
  }
};

var attachSubmit = function(form) {
  var submit = function(e) {
    e.preventDefault();

    var success = getSuccessFor(form);
    var error = getErrorFor(form);

    Promise.resolve(form)
      .then(function () {
        displayLoading(form);
        switchButtonState(form, true);
      })
      .then(request)
      .then(success)
      .catch(error)
      .finally(function () {
        switchButtonState(form, false);
      });
  };

  form.addEventListener('submit', submit);
};

forms.map(attachSubmit);
