'use strict';

var Handlebars = require('handlebars/runtime');

var iterateRangeHelper = function(minNumber, maxNumber, block) {
  var output = '';

  for (var i = minNumber; i < maxNumber + 1; ++i) {
    output += block.fn(i);
  }

  return output;
};

Handlebars.registerHelper('iterateRange', iterateRangeHelper);

module.exports = iterateRangeHelper;
