'use strict';

var originalWindow = window;

function WindowManager() {
  this.window = originalWindow;
  return this;
}

WindowManager.prototype.get = function() {
  return this.window;
};

WindowManager.prototype.set = function(_window) {
  this.window = _window;
};

module.exports = new WindowManager();
