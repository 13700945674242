"use strict";

var Handlebars = require("handlebars/runtime");
var config = require("./config");

var urlHelper = function(url) {
return config.get(url);
};

Handlebars.registerHelper("url", urlHelper);

module.exports = urlHelper;
