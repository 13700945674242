'use strict';

var configData;

var getLocale = function() {
  var locale = document.documentElement.lang;

  if (locale === 'en') {
    return '';
  }

  return locale;
};

var getTemplateGalleryUrl = function() {
  var locale = getLocale();
  var route = '/templates';

  if (locale) {
    return '/' + locale + route;
  }

  return route;
};

var gatherConfigs = function() {
  var attr = document.body.getAttribute.bind(document.body);

  return {
    locale:
      getLocale(),
    myyolaUrl:
      attr('data-myyola'),
    postLoginUrl:
      attr('data-post_login'),
    registrationRecaptchaEnabled:
      attr('data-registration-recaptcha-enabled') === 'True',
    registrationRecaptchaSiteKey:
      attr('data-registration-recaptcha-site-key'),
    sbuiUrl:
      attr('data-sbui'),
    templateGalleryUrl:
      getTemplateGalleryUrl(),
    publicApi:
      attr('data-public-api'),
  };
};

var get = function(key) {
  if (!configData) {
    configData = gatherConfigs();
  }

  if (key) {
    return configData[key];
  }

  return configData;
};

module.exports = {
  get: get,
};
