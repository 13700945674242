function Timer(duration, options) {
  this.duration = duration;
  this.endTime = Date.now() + duration;
  this.opts = options || {};
  this.listeners = {};
}

Timer.calculateTime = function (timestamp) {
  var days = timestamp > 0 ? Math.floor(timestamp / 1000 / 60 / 60 / 24) : 0;
  var hours = timestamp > 0 ? Math.floor(timestamp / 1000 / 60 / 60) % 24 : 0;
  var minutes = timestamp > 0 ? Math.floor(timestamp / 1000 / 60) % 60 : 0;
  var seconds = timestamp > 0 ? Math.floor(timestamp / 1000) % 60 : 0;

  var daysString = days > 0 ? days + 'd ' : '';
  var minutesString = minutes < 10 ? '0' + minutes : minutes.toString();
  var secondsString = seconds < 10 ? '0' + seconds : seconds.toString();

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    toString: function () {
      return daysString + hours + ':' + minutesString + ':' + secondsString;
    }
  };
};

Timer.prototype.run = function () {
  var ONE_SECOND = 1000;
  var refreshTime = this.opts.refreshTime || ONE_SECOND;
  var calculateTime = function () {
    var time = this.endTime - Date.now();

    var newTime = Timer.calculateTime(time);

    this.trigger('change', newTime);

    if (time <= 0) {
      this.stop();
    }
  };

  this.intervalId = setInterval(calculateTime.bind(this), refreshTime)

  return this.getTime();
};

Timer.prototype.getTime = function () {
  return Timer.calculateTime(this.endTime - Date.now());
};

Timer.prototype.stop = function () {
  clearInterval(this.intervalId);

  var finishTime = Timer.calculateTime(this.endTime - Date.now());

  this.trigger('finish', finishTime);
};

Timer.prototype.on = function (eventName, callback) {
  this.listeners[eventName] = this.listeners[eventName] || [];

  this.listeners[eventName].push(callback);
};

Timer.prototype.off = function (eventName, callback) {
  if (!eventName) {
    this.listeners = {};
    return;
  }

  if (!callback) {
    this.listeners[eventName] = [];
    return;
  }

  this.listeners[eventName] = this.listeners[eventName].filter(function (cb) {
    return cb !== callback;
  });
};

Timer.prototype.trigger = function (eventName, data) {
  var callbacks = this.listeners[eventName] || [];

  callbacks.forEach(function (cb) {
    cb(data);
  });
}

module.exports = {
  create: function (deadline) {
    return new Timer(deadline);
  },
};