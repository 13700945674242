'use strict';

var $ = require('jquery');
var buildMessageMarkup = require('./build-message-markup');

var toDataAttribute = function(error) {
  return error.toLowerCase().split(' ').join('-');
};

var getErrorFor = function(form) {
  return function(errorThrown) {
    var $form = $(form);
    var dataError = toDataAttribute(errorThrown.message);
    var message = $form.data(dataError);
    var fallbackMessage = $form.data('unknown-error');

    $form.message('close');

    $form.message({
      classes: 'ws-ui-notification-message ws-ui-notification-message--error',
      message: buildMessageMarkup(message || fallbackMessage),
      clean: false
    });

    $form.message({
      classes: 'ws-ui-notification-message ws-ui-notification-message--error',
      message: buildMessageMarkup($form('make-correction'))
    });
  };
};

module.exports = getErrorFor;
