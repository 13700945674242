const cookies = require("js-cookie");
var localStorageKey = 'AB_TimerConfig';

var FIVE_DAYS_IN_MILLISECONDS = 5 * 24 * 60 * 60 * 1000;

function calculateDiscountFinish(duration) {
  var date = new Date();
  var time = date.getTime();

  var hoursInMilliseconds = date.getHours() * 60 * 60 * 1000;
  var minutesInMilliseconds = date.getMinutes() * 60 * 1000;
  var secondsInMilliseconds = date.getSeconds() * 1000;
  var milliseconds = date.getMilliseconds();

  // start time is the start of the day
  var startTime = time - hoursInMilliseconds - minutesInMilliseconds - secondsInMilliseconds - milliseconds;

  return {
    startTime: startTime,
    endTime:  startTime + duration,
    duration: duration,
  };
}

function calculateAnonymousDiscountTime(options) {
  var duration = options.duration;
  var resetPoint = options.resetPoint || FIVE_DAYS_IN_MILLISECONDS;
  var signUpDateCookie = cookies.get("yola_user_signup_date");

  if (signUpDateCookie) {
    var signUpDate = new Date(signUpDateCookie).getTime();
    var timeFromSignUp = Date.now() - signUpDate;
    return duration - timeFromSignUp
  }

  var storedData = localStorage.getItem(localStorageKey)
  var config = storedData ? JSON.parse(storedData) : {};
  var currentTime = new Date().getTime();

  if (config.duration !== duration || config.endTime - currentTime < resetPoint) {
    var data = calculateDiscountFinish(duration);

    localStorage.setItem(localStorageKey, JSON.stringify(data));

    return data.endTime - currentTime;
  }

  return config.endTime - currentTime;
}

module.exports = calculateAnonymousDiscountTime;
