export const ACTIVE_TAB_CLASS = 'swiper-slide-active';
export const MAX_SCROLL_WINDOW_WIDTH = 1250;
export const PAYWALL_CONTAINER_DISABLED_CLASS = 'paywall__container--disabled';
export const PAYWALL_CONTAINER_CLONE_CLASS = 'paywall__container--clone';
export const PAYWALL_CONTAINER_ACTIVE_CLASS = 'paywall__container--active';
export const PAYWALL_CONTAINER_CLASS = 'paywall-container';
export const PAYWALL_CONTAINER_HOSTING_CLASS = 'paywall-container--hosting';
export const PAYWALL_CONTAINER_ECOMMERCE_CLASS = 'paywall-container--ecommerce';
export const PAYWALL_DESKTOP_CLASS = 'paywall-desktop';
export const PAYWALL_CLASS = 'paywall';
export const PAYWALL_HOSTING_CLASS = 'paywall-hosting';
export const PAYWALL_ECOMMERCE_CLASS = 'paywall-ecommerce';
export const PAYWALL_PLAN_CLASS = 'paywall__plan';
export const PAYWALL_PLANS_CLASS = 'paywall__plans';
export const PAYWALL_BODY_CLASS = 'paywall__body';
export const PAYWALL_TOGGLE_CLASS = 'paywall-mobile-toggler';
export const PAYWALL_FEATURES_LIST_CLASS = 'paywall__feature-list';
export const PAYWALL_FEATURES_LIST_DISABLED_CLASS = 'paywall__feature-list--disabled';
export const PAYWALL_PLAN_TOGGLER_CLASS = 'paywall__plan-toggler';
export const PAYWALL_TOGGLER_PROMO_CLASS = 'paywall__toggler-promo';
export const PAYWALL_TOGGLER_PROMO_DISABLED_CLASS = 'paywall__toggler-promo--disabled';
export const PAYWALL_ITEM_ONLY_ANNUALLY_CLASS = 'paywall__list-item--only-annually';
export const PAYWALL_FEATURE_LIST_ONLY_ANNUALLY_CLASS = 'paywall__feature-list-item--only-annually';
export const PAYWALL_FEATURE_LIST_UNAVAILABLE_CLASS = 'paywall__feature-list-item--unavailable';
export const PAYWALL_LIST_CELL_CLASS = 'paywall__list-cell';
export const PAYWALL_LIST_CELL_UNAVAILABLE_CLASS = 'paywall__list-cell--unavailable';
export const PAYWALL_LIST_CELL_AVAILABLE_CLASS = 'paywall__list-cell--available';
export const PAYWALL_PLAN_PRICE_DESCRIPTION_CLASS = 'paywall__plan-price-description';
export const PAYWALL_PLAN_PRICE_DESCRIPTION_HIDDEN_CLASS = 'paywall__plan-price-description--hidden';
export const ACTIVE_HEADER_CLASS = 'paywall__container--active';
export const MOBILE_TOGGLER_CLASS = 'billing-toggle';
export const TOGGLE_ATTRIBUTE = 'data-toggle';
export const TOGGLE_SHOW = 'show';
export const TOGGLE_HIDE = 'hide';
export const TOGGLE_TRIGGER_CLASS = 'paywall-billing-toggle__trigger';
export const TOGGLE_TRIGGER_MOBILE_CLASS = 'paywall-billing-toggle__trigger--mobile';
export const TOGGLE_TARGET_ATTRIBUTE = 'data-billing-term';
export const TOGGLE_TERM_ATTRIBUTE = 'data-billing-toggle-term';
export const BILLING_TOGGLE_TARGET_ATTRIBUTE = 'data-billing-toggle-target';
export const MONTH_VALUE = 'month';
export const YEAR_VALUE = 'year';
export const ACTIVE_CLASS = 'active';
export const SWIPER_WRAPPER_CLASS = 'swiper-wrapper';
export const SWIPER_SLIDE_CLASS = 'swiper-slide';
export const INVARIABLE_ATTRIBUTE = 'data-invariable';


export const paywallNamesMap = {
  [PAYWALL_HOSTING_CLASS]: PAYWALL_CONTAINER_HOSTING_CLASS,
  [PAYWALL_ECOMMERCE_CLASS]: PAYWALL_CONTAINER_ECOMMERCE_CLASS,
};

export const activeClonesMap = {
  0: PAYWALL_CONTAINER_HOSTING_CLASS,
  1: PAYWALL_CONTAINER_ECOMMERCE_CLASS,
};
