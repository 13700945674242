"use strict";

var _ = require("lodash");
var math = require("mathjs");
var yoproducts = require('@yola/yoproductsjs').default;
const productsDataStorage = require("../helpers/products-data-storage");
const constants = require("./constants");
var getDiscountInfo = require("../helpers/get-discount-info");

const { subscriptionTypes, pricing } = constants;

var getIsUserAuthorized = function() {
  const userId = document.body.getAttribute("data-user-id");
  return Boolean(userId);
}

var getPrice = function({ product, currency, isExtraPrice, discountValue = 0 }) {
  // consider price to be current price and extraPrice to be price without discount
  var price = parseFloat(product.prices[currency]);
  var extraPrice = 0;

  if (discountValue > 0 && product.extra_prices) {
    if (getIsUserAuthorized()) {
      extraPrice = parseFloat(product.extra_prices['no_discounts'][currency]);
    } else {
      extraPrice = parseFloat(product.prices[currency]);
      price = parseFloat(product.extra_prices[`initial_package_discount:${discountValue}`][currency]);
    }
  }

  if (isExtraPrice) return extraPrice;

  const isPriceExist = !price && price !== 0;

  if (isPriceExist) {
    throw new Error(
      "Price doesn't exist for \"" + product.id + '" and "' + currency + '"'
    );
  }

  return price;
};

var calculatePrice = function({
  value,
  currency,
  isExtraPrice = false,
  discountValue
}) {
  const productsTree = productsDataStorage.get();
  const productsList = yoproducts.actions.makeProductsList(productsTree);
  const productsMap = _.keyBy(productsList, "id");
  var product = productsMap[value];

  if (product) {
    return getPrice({ product, currency, isExtraPrice, discountValue });
  }
  for (var i = 0; i < productsList.length; i++) {
    product = productsList[i];
    if (value !== 0) value = value.replace(product.id, getPrice({ product, currency, isExtraPrice, discountValue }));
  }

  return math.evaluate(value);
};

const getDiscount = (subscriptions, currency) => {
  const productsTree = productsDataStorage.get();
  const discounts = [];

  subscriptions.forEach(subscription => {
    const product = productsTree[subscription];

    if (!product) return;

    const annual = product.P1Y.prices[currency];
    const month = product.P1M.prices[currency];
    const discount = Math.round(((12 * month - annual) * 100) / (12 * month));

    discounts.push(discount);
  });

  return Math.max(...discounts);
};

const calcDiscount = currency => {
  const hosting = Object.values(subscriptionTypes.hosting);
  const ecommerce = Object.values(subscriptionTypes.ecommerce);

  return {
    hosting: getDiscount(hosting, currency),
    ecommerce: getDiscount(ecommerce, currency)
  };
};

const setDiscount = currency => {
  const hostingEl = document.querySelectorAll(".paywall__hosting-discount");
  const ecommerceEl = document.querySelectorAll(".paywall__ecommerce-discount");
  const discount = calcDiscount(currency);

  if (hostingEl.length) {
    hostingEl.forEach(el => {
      const element = el;
      element.textContent = discount.hosting;
    });
  }
  if (ecommerceEl.length) {
    ecommerceEl.forEach(el => {
      const element = el;
      element.textContent = discount.ecommerce;
    });
  }
};

const formatPrice = price => {
  const currencyPattern = /[^\d\.\,\s]+/g;
  const currency = price
    .match(currencyPattern)
    .filter(Boolean)
    .join()
    .trim();

  const fullPrice = price
    .split(currencyPattern)
    .filter(Boolean)
    .join()
    .trim();

  const mainSplit = fullPrice.split(/[.|,]+/);

  const main = mainSplit
    .splice(0, mainSplit.length - 1)
    .filter(Boolean)
    .join();
  const cents = mainSplit[mainSplit.length - 1];

  return {
    main: `${currency}${main}`,
    cents,
    full: `${currency}${main}.${cents}`
  };
};

const hideAnimation = (els, isDiscountExperimentEnabled) => {
  const ANIMATION_CLASS = "paywall__plan-price--skeleton";
  const FIRST_PRICE_DISABLED_CLASS = "paywall__plan-price-first--disabled";
  const SECOND_PRICE_DISABLED_CLASS =
    "paywall__plan-price-second-wrapper--disabled";
  const isPricesLoaded = Boolean(productsDataStorage.get());

  if (!isPricesLoaded) return;

  els.forEach(el => {
    const priceElement = el.closest(".paywall-text");

    if (!priceElement) return;

    priceElement.classList.remove(ANIMATION_CLASS);
    Array.from(priceElement.children).forEach(child => {
      child.classList.remove(FIRST_PRICE_DISABLED_CLASS);
      child.classList.remove(SECOND_PRICE_DISABLED_CLASS);
    });
  });

  if (isDiscountExperimentEnabled) {
    const HIDDEN_EXTRA_PRICE_SECTIO_CLASS = 'paywall__plan-extra-price-section--hidden';
    document.querySelectorAll(`.${HIDDEN_EXTRA_PRICE_SECTIO_CLASS}`).forEach((el) => {
      el.classList.remove(HIDDEN_EXTRA_PRICE_SECTIO_CLASS);
    })
  }
};

var decorateExtraPrice = function(element) {
  const extraPriceSection = element.closest(".paywall__plan-extra-price-section");
  const priceSection = extraPriceSection.previousElementSibling;
  if (!priceSection) return;

  extraPriceSection.classList.remove("paywall__plan-extra-price-section--disabled");

  priceSection.querySelectorAll(".paywall-text--theme-default").forEach((el) => {
    el.classList.remove("paywall-text--theme-default");
    el.classList.add("paywall-text--theme-primary");
  })
}

var setElementPrice = function({ currency, locale, discountValue }, el) {
  const element = el;
  const value =
    element.getAttribute("data-product") ||
    element.getAttribute("data-product-cent");
  const extraValue = element.getAttribute("data-extra-product");
  const isAnnualDomainPrice =
    element.getAttribute("data-product") === "domain-com-p1y";
  var price;

  if (isAnnualDomainPrice) {
    price = calculatePrice({ value: pricing.FREE, currency, discountValue });
  } else if (value) {
    price = calculatePrice({ value, currency, discountValue });
  } else if (extraValue) {
    price = calculatePrice({ value: extraValue, currency, discountValue, isExtraPrice: true });
    if (price) decorateExtraPrice(element);
  } else {
    price = element.getAttribute("data-price");
  }

  const formattedPrices = formatPrice(
    yoproducts.actions.formatPrice(price, locale, currency)
  );

  if (!element.closest(".custom-domain-section") && !element.hasAttribute("data-extra-product")) {
    if (element.hasAttribute("data-product")) {
      element.textContent = formattedPrices.main;
    } else {
      element.textContent = formattedPrices.cents;
    }
  } else {
    element.textContent = formattedPrices.full;
  }

  element.setAttribute("data-currency", currency);
};

/**
 * Product placeholders have the following format:
 *   [product](VALUE)
 *
 * Examples:
 *   [product](gold-p1y)
 *   [product](silver-p1y/12+ecommerce-p1m)
 */
var findPlaceholder = function(str) {
  return str.match(/\[product\]\(([a-z0-9-/+]+)\)/);
};

var replacePlaceholder = function(currency, locale, str) {
  var results = findPlaceholder(str);
  var placeholder = results[0];
  var value = results[1];
  var price = calculatePrice({ value, currency });
  var formattedPrice = yoproducts.actions.formatPrice(price, locale, currency);

  return str.replace(placeholder, formattedPrice);
};

var setPrices = function(currency, locale) {
  return getDiscountInfo().then(function (discountInfo) {
    let els = document.querySelectorAll(
        "[data-product], [data-extra-product], [data-price], [data-product-cent]"
    );

    // Convert NodeList to an Array
    els = Array.prototype.slice.call(els);

    els.map(setElementPrice.bind(null, {
      currency,
      locale,
      discountValue: discountInfo.value
    }));

    var desc = document.querySelector('meta[name="description"]');

    if (findPlaceholder(desc.content)) {
      desc.content = replacePlaceholder(currency, locale, desc.content);
    }

    setDiscount(currency);
    hideAnimation(els, Boolean(discountInfo.value));
  });
};

var prices = {
  setPrices: setPrices
};

module.exports = prices;
