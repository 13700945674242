'use strict';

var removeErrorMessage = function (fieldName) {
  document.querySelector('[data-field=' + fieldName + ']').textContent = '';
};

var clearError = function (fieldEl) {
  fieldEl.classList.remove('invalid-field');

  removeErrorMessage(fieldEl.name);
};

var clearAllErrors = function () {
  var invalidFields = [].slice.call(
    document.querySelectorAll('.invalid-field')
  );

  invalidFields.forEach(clearError);
};

var showError = function (field) {
  var errorsResponse = this;

  var fieldEl = document.querySelector('[name=' + field + ']');
  var errorEl = document.querySelector('[data-field=' + field + ']');

  errorEl && (errorEl.textContent = errorsResponse[field].join(' '));
  fieldEl && fieldEl.classList.add('invalid-field');
};

exports.clearError = clearError;
exports.clearAllErrors = clearAllErrors;
exports.showError = showError;
