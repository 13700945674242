'use strict';
var dmca = require('./dmca');


var phishingFields = [
  'email',
  'offending_url',
  'message',
];

var copyrightFields = [
  'email',
  'offending_url',
  'copyrighted_works',
  'infringing_material',
  'address',
  'telephone',
  'message',
  'signature_firstname',
  'signature_lastname',
  'dmca_blurb'
];

var obscenityFields = [
  'email',
  'offending_url',
  'message'
];

var illegalActivityFields = [
  'email',
  'offending_url',
  'law_violated',
  'message'
];

var tosFields = [
  'email',
  'offending_url',
  'tos_violated',
  'message'
];

var fields = {
  phishing: phishingFields,
  copyright: copyrightFields,
  obscenity: obscenityFields,
  illegal: illegalActivityFields,
  tos: tosFields
};

var abstractFields = {};

var getValue = function (fieldName) {
  if (abstractFields[fieldName]) {
    return abstractFields[fieldName]();
  }

  return document.querySelector('[name=' + fieldName + ']').value;
};

var getDmca = function () {
  return dmca.generateDmcaMessage(
    getValue('signature_firstname'),
    getValue('signature_lastname')
  );
};

var extractData = function (subject) {
  return fields[subject].reduce(function (accum, field) {
    accum[field] = getValue(field);

    return accum;
  }, {});
};

abstractFields.dmca_blurb = getDmca;

exports.getValue = getValue;
exports.extractData = extractData;
