/*jslint vars: true, browser: true, devel: true, indent: 4 */

(function ($) {
    'use strict';
    /*
        domain selector plugin
        flow of the code is:
            - Parse domain name from user input
            - Delay the query (fingers type too fast)
            - Hit the domain serivce, check if domain is available
            - Update the container
            - Start the whole shebang with init
    */

    var defaults = {
        successHandler: function(){},
        prefill: window.location.search
    };

    $.fn.domainSelector = function (options) {

        var settings = $.extend(defaults, options);

        var parseInput, hitDomainService;
        var timer, container = this;

        var getUserSearch = function(){
            return container.find('.search').val();
        };

        var getTopLevelDomain = function(){
            return container.find('.tld-select').val();
        };

        var ensureSlash = function(val){
            if (val[0] === '/'){
                return val;
            }
            else {
                return '/' + val;
            }
        };

        /* Get and validate user input */
        var savedInput = '';
        var domainName = '';

        parseInput = function () {
            var name = getUserSearch();
            var tld = getTopLevelDomain();
            var newInput = name + tld;
            var previousInput = savedInput;
            savedInput = newInput;
            if (newInput === previousInput) { return; } /* ignore bum keystrokes */
            if (name.length < 3) { return container.reset(); }
            if (!name.match(/^[a-zA-Z0-9\-]+$/)) { return container.invalid(); }
            container.checking();
            domainName = newInput;
            delayedQuery();
        };


        /* fingers type too fast, hurts domain service */
        var delayedQuery = function () {
            clearTimeout(timer);
            timer = setTimeout(hitDomainService, 750);
        };


        /* hit domain service */
        var serviceURL = container.data('url');
        var ajax = { abort : function () {} };
        var previousSearch = '';
        hitDomainService = function () {
            ajax.abort();
            if (previousSearch === domainName) { return container.previous(); }
            ajax = $.ajax({url: serviceURL + '/' + domainName + settings.prefill})
                .done(container.results)
                .fail(container.error);
        };

        var continueClicked = function(event){
            // If we're navigating away from the current page
            // when this is clicked, we don't need to fire
            // other events.
            if ($(this).attr('href')){
                return;
            }

            event.preventDefault();

            var args = {
                domain: getUserSearch(),
                tld: getTopLevelDomain()
            };
            settings.successHandler(args);
            container.trigger('domainSelected', args);
        };

        /* Container states */
        container.init = function (html) {
            // save initial html for reset state, attach events, show html
            container.data('reset', html);
            container.on('keyup', '.search', parseInput);
            container.on('change', '.tld-select', parseInput);
            container.on('click', 'a[data-domain]', continueClicked);
            container.update(html);
        };
        container.results = function (html) {
            // set variables for the 'previous' state, show html
            previousSearch = domainName;
            container.data('previous', html);
            container.update(html);
        };
        container.previous = function () {
            // save some hits against service
            var html = container.data('previous');
            container.update(html);
        };
        container.reset = function () {
            ajax.abort();
            clearTimeout(timer);
            var html = container.data('reset');
            container.update(html);
        };
        container.checking = function () {
            var html = container.data('reset');
            container.update(html);
            container.find('.dropdown').show();
        };
        container.invalid = function () {
            // when user types chars other than [a-z0-9-]
            container.reset();
            var input = container.find('.search');
            var msg = '<h3 class="error">' + input.data('validation_title') + '</h3>';
            msg += '<p class="error">' + input.data('validation_msg') + '</p>';
            container.find('.checking').html(msg);
            container.find('.dropdown').show();
        };
        container.error = function (e) {
            // service ate it or an invalid domain was searched for
            if (e.statusText === 'abort') { return; }
            var input = container.find('.search');
            var msg = '<p class="error">' + input.data('server_error') + '</p>';
            container.find('.checking').html(msg);
            container.find('.dropdown').show();
        };
        container.update = function (html) {
            // Swap-in new html and don't lose the user's input an
            var input = container.find('.search').val();
            var tld = container.find('.tld-select').val();
            container.html(html);
            // reset values and set focus
            if (input) { container.find('.search').val(input); }
            if (tld) { container.find('.tld-select').val(tld); }

            // disables focus on initial load
            if (container.data('focus') === false) {
                container.data('focus', true);
                return;
            }

            container.find('.search').focus();
            /* IE focus bug, force cursor to end of input */
            var el = container.find('.search')[0];
            if(el.setSelectionRange){
                var len = el.value.length;
                el.setSelectionRange(len, len);
            }
        };

        /* init selector and attach search */
        $.ajax({url: serviceURL + ensureSlash(settings.prefill)})
            .done(container.init);

    };// end domainSelector

}(window.jQuery));
