var $ = require('jquery');

var formDefaults = function() {
    $('#global-signup_cnpj').inputmask('mask', {'mask': '99.999.999/9999-99'});
    $('#global-signup_company_startup_date, #global-signup_individual_dob')
        .inputmask('99/99/9999', { 'placeholder': 'DD/MM/AAAA', 'clearIncomplete': true });
    $('#global-signup_company_cep, #global-signup_individual_cep').inputmask('99999-999');
    $('#global-signup_individual_cpf').inputmask('999.999.999-99');
    $('#global-signup_econ_cnae').inputmask('9999-9/99');
    $('#global-signup_individual_phone').inputmask('(99)9999-9999[9]');
};

module.exports = formDefaults;
