'use strict';

var config = require('./config');

var recaptcha;

var getSrc = function() {
  return [
    'https://www.google.com/recaptcha/api.js',
    '?hl=',
    document.documentElement.lang,
    '&onload=onRecaptchaLoad',
    '&render=explicit',
  ].join('');
};

var importRecaptcha = function() {
  return new Promise(function(resolve, reject) {
    var script = document.createElement('script');

    script.src = getSrc();
    script.onerror = reject;
    window.onRecaptchaLoad = function() {
      resolve(window.grecaptcha);
    };

    document.body.appendChild(script);
  });
};

var load = function() {
  if (!recaptcha.loading) {
    if (window.grecaptcha) {
      recaptcha.loading = Promise.resolve(window.grecaptcha);
    } else {
      recaptcha.loading = importRecaptcha();
    }
  }

  return recaptcha.loading;
};

var render = function(container, options) {
  options || (options = {});

  options.sitekey = config.get('registrationRecaptchaSiteKey');

  var renderWidget = function() {
    window.grecaptcha.render(container, options);
  };

  return load()
    .then(renderWidget);
};

recaptcha = {
  load: load,
  loading: null,
  render: render,
};

module.exports = recaptcha;
