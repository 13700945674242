var fetchUserPreferences = require('./fetch-user-preferences');
var getUser = require('./get-user');
var fetchSubscriptions = require('./fetch-subscriptions');
var calculateAnonymousDiscountTime = require('./calculate-anonymous-discount-time');

// 7 days
var DISCOUNT_PERIOD_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;
var PREMIUM_B2C_TYPES = ['bronze', 'silver', 'gold'];

function getDiscountInfo() {
  return getUser({ useCachedData: true })
    .then(function (userData) {
      return Promise.all([
          fetchUserPreferences(),
          fetchSubscriptions({ typeIn: PREMIUM_B2C_TYPES })
        ])
        .then(function (data) {
          var userProperties = data[0].body;
          var subscriptions = data[1].body;

          return {
            userProperties: userProperties,
            premiumSubscriptions: subscriptions,
            userData: userData,
          }
        });
    })
    .then(function (data) {
      var userData = data.userData;
      var userProperties = data.userProperties;
      var hasEverHadPremiumSubscriptions = Boolean(data.premiumSubscriptions.length);

      var initialPackageDiscount = parseInt(userProperties.initial_package_discount, 10) || 0;
      var signUpDate = new Date(userData.signupDate);
      var signUpUtcDate = new Date(
        Date.UTC(
          signUpDate.getFullYear(),
          signUpDate.getMonth(),
          signUpDate.getDate(),
          signUpDate.getHours(),
          signUpDate.getMinutes(),
          signUpDate.getSeconds(),
          signUpDate.getMilliseconds()
        )
      );

      var discountDuration = DISCOUNT_PERIOD_IN_MILLISECONDS - (Date.now() - signUpUtcDate.getTime());

      if (initialPackageDiscount && !hasEverHadPremiumSubscriptions && discountDuration > 0) {
        return {
          duration: discountDuration,
          value: initialPackageDiscount
        };
      }

      return {
        duration: 0,
        value: 0
      };
    })
    .catch(function () {
      if (window.INITIAL_PACKAGE_DISCOUNT) {
        return {
          value: window.INITIAL_PACKAGE_DISCOUNT,
          duration: calculateAnonymousDiscountTime({
            duration: DISCOUNT_PERIOD_IN_MILLISECONDS
          }),
        };
      }

      return {
        duration: 0,
        value: 0
      };
    });
}

module.exports = getDiscountInfo;
