import {
  ACTIVE_TAB_CLASS,
  PAYWALL_CONTAINER_ACTIVE_CLASS,
} from './constants';

const isActiveTab = (element, isIgnored) => {
  if (isIgnored) return true;
  return !!(element.closest(`.${ACTIVE_TAB_CLASS}`) || element.closest(`.${PAYWALL_CONTAINER_ACTIVE_CLASS}`));
};

export default isActiveTab;
