'use strict';

function buildMessageMarkup(message) {
  return (
    '<p class="ws-ui-text ws-ui-text--medium ws-ui-text--medium-emphasis ws-ui-text--align-left">' +
      '<span>' + message + '</span>' +
    '</p>'
  );
}

module.exports = buildMessageMarkup;