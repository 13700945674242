'use strict';

var _ = require('lodash');

var displayLoading = require('./helpers/form-display-loading');
var renderRecaptcha = require('./helpers/render-captcha');
var requests = require('@yola/yosupportjs').requests;
var getErrorFor = require('./helpers/form-error');
var getRedirectFor = require('./helpers/redirect');
var submitButton = require('./helpers/submit-button');

var forms = [].slice.call(document.querySelectorAll('.contact-form'));

var checkEmailsMatch = function() {
  var emailOne = document.getElementById('id_email').value;
  var emailTwo = document.getElementById('id_email_confirmation').value;

  if (emailOne !== emailTwo){
    throw new Error('Emails do not match');
  }
};

var checkReason = function() {
  var selectReason = document.getElementById('id_reason');
  var reasonCode = '22';
  if (selectReason) {
    reasonCode = selectReason.value;
  }
  if (reasonCode === '0') {
    selectReason.classList.add('error-reason');
    throw new Error('Please select a subject');
  }
};

var request = function() {
  // only english has defined reason codes
  // all other languages use code 22 for 'Other'
  var reasonElement = document.getElementById('id_reason');
  var reasonCode;
  if (reasonElement) {
    reasonCode = reasonElement.value;
    reasonElement.classList.remove('error-reason');
  } else {
    reasonCode = '22';
  }

  var options = {
    email: document.getElementById('id_email').value,
    first_name: document.getElementById('id_first_name').value,
    language: document.documentElement.lang,
    reason: reasonCode,
    message: document.getElementById('id_message').value,
    recaptcha_response: document.getElementById('g-recaptcha-response').value,
  };

  return requests.tickets(options);
};

var formatErrors = function(errors) {
  var errorMessageContainer = document.querySelector('.err-message');

  var errorMessages = Object.keys(errors).reduce(function(htmlString, key) {
    if(key === 'reason') {
      return htmlString += '<br>' + 'Please select a subject';
    }
    var newError = '<br>' + key + ': ' + _.get(errors, key);

    htmlString += newError;

    return htmlString;
  }, '');

  errorMessageContainer.innerHTML += errorMessages;
};

var attachSubmit = function(form) {
  renderRecaptcha('#recaptcha');
  var submit = function(e) {
    e.preventDefault();

    submitButton.disable(form);

    var error = getErrorFor(form);
    var redirect = getRedirectFor(form);

    var errorHandler = function(errorThrown) {
        submitButton.enable(form);
        error(errorThrown);

        if (errorThrown.response) {
          errorThrown.response.json().then(function(json) {
            formatErrors(json);
          });
        }
    };

    Promise.resolve(form)
      .then(displayLoading)
      .then(checkEmailsMatch)
      .then(checkReason)
      .then(request)
      .then(redirect)
      .catch(errorHandler);
  };

  form.addEventListener('submit', submit);
};

forms.map(attachSubmit);
