import {
  PAYWALL_CONTAINER_CLONE_CLASS,
  PAYWALL_CONTAINER_ACTIVE_CLASS,
  activeClonesMap,
} from './constants';

export default (activeSlide) => {
  if (activeSlide < 0) return;

  const activeCloneName = activeClonesMap[activeSlide];
  const containerClones = Array.from(document.querySelectorAll(`.${PAYWALL_CONTAINER_CLONE_CLASS}`));
  const clone = containerClones.find(containerClone => containerClone.classList.contains(activeCloneName));

  containerClones.forEach(containerClone => {
    if (!containerClone) return;
    containerClone.classList.remove(PAYWALL_CONTAINER_ACTIVE_CLASS);
  });
  if (!clone) return;
  clone.classList.add(PAYWALL_CONTAINER_ACTIVE_CLASS);
};
